import React from "react";
import style from "./NotFound.module.css";

export const NotFound = () => {
  return (
    <div className={style.notFound}>
      404 <br />
      Pagina no encontrada
    </div>
  );
};
